import './Popular.scss';
import empty from '../../assets/images/res370-232.png';
import { Carousel } from '../Carousel/Carousel';
import { CarouselItem } from '../Carousel/Carousel';
import { Offer } from '../Offer/Offer';
import popularData from '../../assets/data/popular.json'




function Popular() {
    return (
        <div id="popular_cruises1">
            <div className="container">

                <h2 className="animate__animated">POPULAR CRUISES</h2>

                <div className="subtitle animate__animated">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                    euismod <br />tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </div>

                <br /><br />



                <div id="popular_wrapper" className="animate__animated animate__flipInY" data-animation="fadeIn" data-animation-delay="300">
                    <div id="popular_inner">
                        <div className="">
                            <div id="popular">
                                <div className="container-fluid text-center my-3">
                                    <div className="row">
                                        <div className="row mx-auto my-auto justify-content-center">
                                            <Carousel id="popular_items" slidesPerPage={3}>
                                                {popularData?.data?.map((popular, index) =>
                                                (<CarouselItem active={index === 0}>
                                                    <Offer title={popular.title}
                                                        description={popular.description}
                                                        hint={popular.hint}
                                                        descriptionOnHover={popular.descriptionOnHover}
                                                        hintOnHover={popular.hintOnHover}
                                                        rating={popular.rating}
                                                        reviewSummary={popular.reviewSummary}
                                                        actionButtonText='Go'
                                                        imageSrc={popular.img}
                                                    ></Offer>
                                                </CarouselItem>)
                                                )}
                                            </Carousel>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>


    );
}

export default Popular;
