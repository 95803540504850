import './Offer.scss';
import React from 'react';
import empty from '../../assets/images/res370-232.png';
import thai from '../../assets/images/thai.jpg';

import Rating from '../Rating/Rating';

interface OfferProps {
    title: string,
    hint: string,
    imageSrc: string,
    description: string,
    hintOnHover: string,
    descriptionOnHover: string,
    rating: number,
    reviewSummary: string,
    actionButtonText: string,
    onCardAction?: React.ReactEventHandler
}

export const Offer: React.FC<OfferProps> = ({
    title,
    hint,
    description,
    hintOnHover,
    descriptionOnHover,
    rating,
    reviewSummary,
    actionButtonText,
    imageSrc,
    onCardAction
}
) => {


    return (
        <div className="">
            <div className="offer">
                <div className="offer_inner">
                    <figure>
                        <img src={require('../../assets/images/' + imageSrc)} alt=""
                            className="img-responsive" />
                        <div className="over">
                            <div className="title_over">{title} <span>{hintOnHover}</span>
                            </div>
                            <div className="description_over">{descriptionOnHover}
                            </div>
                        </div>
                    </figure>
                    <div className="caption">
                        <div className="title"><span>{title}</span> {hint}
                        </div>
                        <div className="description">{description}
                        </div>
                        <div className="tool_bar clearfix">
                            <div className="left_side">
                                <Rating value={rating} />
                                <div className="nums">{reviewSummary}</div>
                            </div>
                            <div className="right_side"><a href="#"
                                className="btn-default btn1" onClick={onCardAction}>{actionButtonText}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Offer;
