import './Article.scss';

interface ArticleProps {
    title: string,
    summary: string,
    icon: string,
    onArticleAction?: React.ReactEventHandler
}

export const Article: React.FC<ArticleProps> = ({
    title,
    summary,
    icon,
    onArticleAction
}
) => {
    return (
        <div className="thumbnail clearfix">
                                <a href="#" onClick={onArticleAction && onArticleAction}>
                                    <figure className="">
                                     <span className={"active fa fa-" + icon} style={{fontSize: "40px"}}></span>
                                        
                                    </figure>
                                    <div className="caption">
                                        <div className="txt1">{title}</div>
                                        <div className="txt2">
                                            {summary}
                                        </div>
                                        <div className="txt3">Read More</div>
                                    </div>
                                </a>
                            </div>
    );
}

export default Article;
