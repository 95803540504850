import './Rating.scss';
import React from 'react';
import star1 from '../../assets/images/star1.png';
import star2 from '../../assets/images/star2.png';

interface RatingProps {
    value: number,
}

export const Rating: React.FC<RatingProps> = ({
    value,
}
) => {
    return (
        <div className="rating">
            {
                [...Array(5)].map((x, i) =>
                    <img src={i < value ? star1 : star2} alt="" />
                )
            }
        </div>
    );
}

export default Rating;
