import './Why.scss';

import articlesData from '../../assets/data/articles.json'
import Article from '../Article/Article';

function Why() {
    return (
        <div id="why1">
            <div className="container">

                <h2 className="animate__animated animate__flipInY ">WHY WE ARE THE BEST</h2>

                <div className="subtitle animate__animated">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                    euismod <br />tincidunt ut laoreet dolore magna aliquam erat volutpat.
                </div>

                <br /><br />

                <div className="row">
                    {
                        articlesData?.data?.map((article, index) => (
                            <div className="col-sm-3">
                                <div className="thumb animate__animated animate__flipInY" data-animation="flipInY" data-animation-delay="200">
                                    <Article title={article.title}
                                        summary={article.summary}
                                        icon={article.icon}

                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    );
}

export default Why;
